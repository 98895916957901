import * as MyCatalogAPI from "../../api/myCatalog";
import { ThunkAction } from "redux-thunk";
import { AppStateType } from "../reducers/mainReducer";
import { RequestMyCatalogFiltersType, RequestPaginationType } from "app/types";
import {
  fetchMyCatalogListRequest,
  fetchMyCatalogListSuccess,
  fetchMyCatalogListFailure,
  fetchPriceTypesRequest,
  fetchPriceTypesSuccess,
  fetchPriceTypesFailure,
  fetchCategoriesListRequest,
  fetchCategoriesListSuccess,
  fetchCategoriesListFailure,
  deleteFromMyCatalogRequest,
  deleteFromMyCatalogSuccess,
  deleteFromMyCatalogFailure,
} from "../slices/myCatalogSlice";

// Получение списка товаров в Моем каталоге
export const fetchMyCatalogList = (params: RequestPaginationType | RequestMyCatalogFiltersType): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(fetchMyCatalogListRequest(""));
  try {
    const response = await MyCatalogAPI.getMyCatalog(params);

    dispatch(fetchMyCatalogListSuccess(response?.data));
  } catch (error: any) {
    dispatch(fetchMyCatalogListFailure(error?.response?.data?.error));
  }
};

// Получение списка типов цен
export const fetchPriceTypes = (): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(fetchPriceTypesRequest(""));
  try {
    const response = await MyCatalogAPI.getPriceTypeList();

    dispatch(fetchPriceTypesSuccess(response?.data));
  } catch (error: any) {
    dispatch(fetchPriceTypesFailure(error?.response?.data?.error));
  }
};

// Получение дерева категорий в Моем каталоге
export const fetchCategoriesList = (): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(fetchCategoriesListRequest(""));
  try {
    const response = await MyCatalogAPI.getCategories();

    dispatch(fetchCategoriesListSuccess(response?.data));
  } catch (error: any) {
    dispatch(fetchCategoriesListFailure(error?.response?.data?.error));
  }
};

// Массовое или единичное удаление товаров из Моего каталога
export const deleteFromMyCatalog = (ids: string[]): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(deleteFromMyCatalogRequest(""));
  try {
    const response = await MyCatalogAPI.deleteFromMyCatalog(ids);

    dispatch(deleteFromMyCatalogSuccess(ids));
  } catch (error: any) {
    dispatch(deleteFromMyCatalogFailure(error?.response?.data?.error));
  }
};
