import React, { JSX, FC } from "react";
import css from "./style.module.css";

interface IEmptyBlockProps {
  icon: JSX.Element;
  title: string;
  description: JSX.Element | string;
  btn?: JSX.Element;
  hint?: JSX.Element | string;
  style?: React.CSSProperties;
}

const EmptyBlock: FC<IEmptyBlockProps> = ({
  icon,
  title,
  description,
  btn,
  hint,
  style,
}): JSX.Element => {
  return (
    <div className={`flex justify-center items-center`} style={{...style}}>
      <div className="flex flex-col justify-center items-center">
        {icon}
        <h4 className={css.title}>{title}</h4>
        <p className="text-16-r text-center secondary-color mb-6">
          {description}
        </p>
        {btn || null}
        {hint && <p className="text-16-r text-center secondary-color">{hint}</p>}
      </div>
    </div>
  );
};

export default EmptyBlock;
