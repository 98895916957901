import React, { JSX, FC, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "redux/store/store";
import { useForm } from "antd/es/form/Form";
import { fetchModeratorCommentsCount } from "../../../redux/thunks/moderatorComments.thunk";
import { InitialModeratorCommentsStateType } from "../../../redux/slices/moderatorCommentsSlice";
import { AppStateType } from "../../../redux/reducers/mainReducer";

// components
import { Badge, Tabs } from "antd";
import TabWrapper from "../TabWrapper/TabWrapper";

import css from "./style.module.css";

interface ITabsWithCountersProps {}

export enum Tab {
	uploaded = "Загруженные товары",
  uploading = "Загрузка товаров",
  comparison = "Сопоставление категорий",
  comments = "Комментарии от модератора",
}

const TabsWithCounters: FC<ITabsWithCountersProps> = (): JSX.Element => {
	const { tab } = useParams();

  const moderatorComments: InitialModeratorCommentsStateType = useSelector(
    (state: AppStateType) => state.moderatorCommentsSlice
  );

	const dispatch = useDispatch<AppDispatch>();

	const [form] = useForm<any>();

	const [activeTab, setActiveTab] = useState<string>(Object.values(Tab)?.[tab] ?? Tab.uploaded);

	useEffect(() => {
		dispatch(fetchModeratorCommentsCount());
	}, [activeTab]);

	const renderLabel = (title: string, count: number = 0): JSX.Element => {
		const countStyle: React.CSSProperties = {
			marginLeft: 4,
			color: activeTab === title ? "#0a84ff" : "#374151",
			backgroundColor: activeTab === title ? "#0A84FF1A" : "#67768C1A"
		};

		return (
			<span>
				{title}
				<Badge
					style={countStyle}
					count={count || null}
					overflowCount={999}
				/>
			</span>
		);
	};

	const onChange = (key: string): void => {
		form.resetFields();
		setActiveTab(key);
	};

	const renderTabPane = (tabKey: Tab, count: number = 0, _initFilters = {}): JSX.Element => (
		<Tabs.TabPane
			key={tabKey}
			tab={renderLabel(tabKey, count)}
		>
			{activeTab === tabKey && (
        <TabWrapper currentTab={tabKey} setActiveTab={setActiveTab} form={form} />
			)}
		</Tabs.TabPane>
	);

	return (
		<div className={css.wrapper}>
			<Tabs
				className="px-6"
				activeKey={activeTab}
				onChange={onChange}
			>
				{renderTabPane(Tab.uploaded)}
				{renderTabPane(Tab.uploading)}
				{renderTabPane(Tab.comparison)}
				{renderTabPane(Tab.comments, moderatorComments.count)}
			</Tabs>
		</div>
	);
};

export default TabsWithCounters;
