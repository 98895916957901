import * as ModeratorCommentsAPI from "../../api/moderatorComments";
import { ThunkAction } from "redux-thunk";
import { AppStateType } from "../reducers/mainReducer";
import {
  fetchModeratorCommentsCountFailure,
  fetchModeratorCommentsCountRequest,
  fetchModeratorCommentsCountSuccess
} from "../slices/moderatorCommentsSlice";

// Получение количества комментариев модератора
export const fetchModeratorCommentsCount = (): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(fetchModeratorCommentsCountRequest(""));
  try {
    const response = await ModeratorCommentsAPI.getModeratorCommentsCount();

    dispatch(fetchModeratorCommentsCountSuccess(response?.data?.count));
  } catch (error: any) {
    dispatch(fetchModeratorCommentsCountFailure(error?.response?.data?.error));
  }
};
