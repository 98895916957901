import { createSlice } from "@reduxjs/toolkit";

export type InitialModeratorCommentsStateType = {
  count: number;
  error: string | null;
  isFetching: boolean;
};

const initialState: InitialModeratorCommentsStateType = {
  count: 0,
  error: null,
  isFetching: false,
};

const moderatorCommentsSlice = createSlice({
  name: "moderatorComments",
  initialState,
  reducers: {
    // Получение количества комментариев модератора
    fetchModeratorCommentsCountRequest(state, _action) {
      state.error = null;
      state.isFetching = true;
    },
    fetchModeratorCommentsCountSuccess(state, action) {
      state.error = null;
      state.isFetching = false;
      state.count = action.payload;
    },
    fetchModeratorCommentsCountFailure(state, action) {
      state.error = action.payload;
      state.isFetching = false;
    },
  },
});

const { actions, reducer } = moderatorCommentsSlice;

export const {
  fetchModeratorCommentsCountRequest,
  fetchModeratorCommentsCountSuccess,
  fetchModeratorCommentsCountFailure,
} = actions;

export default reducer;
