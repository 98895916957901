import { createSlice } from "@reduxjs/toolkit";
import { MyCatalogProductType, PriceType } from "app/types";

export type InitialMyCatalogStateType = {
  total: number;
  page: number;
  pageSize: number;
  error: string | null;
  list: MyCatalogProductType[] | null;
  priceTypes: PriceType[] | null;
  isFetching: boolean;
};

const initialState: InitialMyCatalogStateType = {
  total: 0,
  page: 1,
  pageSize: 10,
  error: null,
  list: null,
  priceTypes: null,
  isFetching: false,
};

const myCatalogSlice = createSlice({
  name: "myCatalog",
  initialState,
  reducers: {
    // Получение списка товаров в Моем каталоге
    fetchMyCatalogListRequest(state, _action) {
      state.error = null;
      state.isFetching = true;
    },
    fetchMyCatalogListSuccess(state, action) {
      state.error = null;
      state.isFetching = false;
      state.list = action.payload.results;
      state.total = action.payload.total;
      state.page = action.payload.page;
      state.pageSize = action.payload.pageSize;
    },
    fetchMyCatalogListFailure(state, action) {
      state.error = action.payload;
      state.isFetching = false;
    },
    // Получение списка типов цен
    fetchPriceTypesRequest(state, _action) {
      state.error = null;
      state.isFetching = true;
    },
    fetchPriceTypesSuccess(state, action) {
      state.error = null;
      state.isFetching = false;
      state.priceTypes = action.payload;
    },
    fetchPriceTypesFailure(state, action) {
      state.error = action.payload;
      state.isFetching = false;
    },
    // Получение дерева категорий в Моем каталоге
    fetchCategoriesListRequest(state, _action) {
      state.error = null;
      state.isFetching = true;
    },
    fetchCategoriesListSuccess(state, action) {
      state.error = null;
      state.isFetching = false;
      state.priceTypes = action.payload;
    },
    fetchCategoriesListFailure(state, action) {
      state.error = action.payload;
      state.isFetching = false;
    },
    // Массовое или единичное удаление товаров из Моего каталога
    deleteFromMyCatalogRequest(state, _action) {
      state.error = null;
      state.isFetching = true;
    },
    deleteFromMyCatalogSuccess(state, action) {
      state.error = null;
      state.isFetching = false;
      state.list = state.list.filter(({id}) => !action.payload.includes(id));
    },
    deleteFromMyCatalogFailure(state, action) {
      state.error = action.payload;
      state.isFetching = false;
    },
  },
});

const { actions, reducer } = myCatalogSlice;

export const {
  fetchMyCatalogListRequest,
  fetchMyCatalogListSuccess,
  fetchMyCatalogListFailure,
  fetchPriceTypesRequest,
  fetchPriceTypesSuccess,
  fetchPriceTypesFailure,
  fetchCategoriesListRequest,
  fetchCategoriesListSuccess,
  fetchCategoriesListFailure,
  deleteFromMyCatalogRequest,
  deleteFromMyCatalogSuccess,
  deleteFromMyCatalogFailure,
} = actions;

export default reducer;
