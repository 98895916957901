import React, { JSX, FC } from "react";
import { Tooltip } from "antd";

interface ITextWithTooltipProps {
  text: string | null | undefined;
  length?: number;
}

const TextWithTooltip: FC<ITextWithTooltipProps> = ({
  text,
  length = 30,
}): JSX.Element => {
  return (
    !!text && (
      <Tooltip title={text?.length > length ? text : null}>
        <div className="flex text-ellipsis overflow-hidden">
          {text?.length > length ? `${text.substring(0, length)}...` : text}
        </div>
      </Tooltip>
    )
  );
};

export default TextWithTooltip;
