const blueColor: string = "#1E4BD2";

export const customTheme = {
  // Глобальные токены для настройки основных цветов antd
  token: {
    colorPrimary: blueColor,
    colorLink: blueColor,
    colorInfo: blueColor,
    colorLinkHover: "rgba(30, 75, 210, 0.86)"
  },
  // Design токены для настройки цветов в отдельных компонентах
  components: {}
};
