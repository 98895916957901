import { LOGOUT } from "../../constants/actionTypes";

type LogoutType = {
  type: typeof LOGOUT;
};

export const logout = (): LogoutType => {
  localStorage.removeItem("authtoken");

  return {
    type: LOGOUT
  };
};
