import React, { JSX, FC } from "react";
import { Layout } from "antd";
import GlobalSearch from "./GlobalSearch/GlobalSearch";
import HeaderNavBar from "./HeaderNavBar/HeaderNavBar";
import HeaderUserInfo from "./HeaderUserInfo/HeaderUserInfo";

interface IHeaderProps {
  isVendor: boolean;
}

const Header: FC<IHeaderProps> = ({isVendor}): JSX.Element => {
  const headerStyle: React.CSSProperties = {
    padding: 18,
    textAlign: "center",
    height: 64,
    lineHeight: "17px",
    borderBottom: "1px solid var(--main-color-secondary)",
    backgroundColor: "#FFFFFF",
  };

  return (
    <Layout.Header style={headerStyle}>
    <div className="flex justify-between items-center">
      <GlobalSearch/>
      <div className="flex items-center">
        <HeaderNavBar isVendor={isVendor}/>
        <HeaderUserInfo />
      </div>
    </div>
    </Layout.Header>
  );
};

export default Header;
