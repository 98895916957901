import axios, { Canceler, CancelTokenStatic } from "axios";
import { message } from "antd";
import { logout } from "../redux/actions/account.actions";

const getToken = (): string | null => {
  const token: string | null = window?.localStorage.getItem("authtoken");

  axios.defaults.headers.common.Authorization = token ? JSON.parse(token) : undefined;

  return token;
};

export const setupResponseInterceptor = (store: any): void => {
  const serverErrors: number[] = [500, 502, 503, 504, 505];
  const clientErrors: number[] = [400, 403, 404];

  axios.interceptors.response.use(null, (error) => {
    if (error?.response && error?.response?.status === 401) {
      store.dispatch(logout());

      if (clientErrors.includes(error?.response?.status)) {
        message.error(error?.response?.data?.detail, 4);
      }

      if (serverErrors.includes(error?.response?.status)) {
        message.error("Произошла непредвиденная ошибка. Пожалуйста, попробуйте позднее", 4);
      }
    }

    return Promise.reject(error);
  });
};

const CancelToken: CancelTokenStatic = axios.CancelToken;
export let cancel: Canceler;

const get = (host: string | undefined, path: string, params?: any) => {
  return axios({
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${JSON.parse(String(getToken()))}`,
    },
    method: "get",
    url: `${host}${path}`,
    params,
    cancelToken: new CancelToken(function executor(canceler: Canceler) {
      cancel = canceler;
    })
  });
};

const put = (host: string | undefined, path: string, body?: any) => {
  return axios({
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${JSON.parse(String(getToken()))}`,
    },
    method: "put",
    url: `${host}${path}`,
    data: body ? JSON.stringify(body) : undefined
  });
};

const post = (host: string | undefined, path: string, body?: any) => {
  return axios({
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${JSON.parse(String(getToken()))}`,
    },
    method: "post",
    url: `${host}${path}`,
    data: body ? JSON.stringify(body) : undefined
  });
};

const patch = (host: string | undefined, path: string, body?: any) => {
  return axios({
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${JSON.parse(String(getToken()))}`,
    },
    method: "patch",
    url: `${host}${path}`,
    data: body ? JSON.stringify(body) : undefined
  });
};

const deleted = (host: string | undefined, path: string, body?: any) => {
  return axios({
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${JSON.parse(String(getToken()))}`,
    },
    method: "delete",
    url: `${host}${path}`,
    data: body ? JSON.stringify(body) : undefined
  });
};

export default {
  GET: get,
  PUT: put,
  POST: post,
  PATCH: patch,
  DELETE: deleted,
};

