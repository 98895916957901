import React, { JSX, FC, useState, useEffect } from "react";
import { NavigateFunction, useNavigate } from "react-router";
import dayjs from "dayjs";
import generateSortString from "utils/generateSortString";
import { checkObjIsEmpty } from "utils/checkObjIsEmpty";
import { TableRowSelection, TablePaginationConfig, SorterResult } from "antd/es/table/interface";
import { calcDeclensionByCount } from "../../../utils/сalcDeclensionByCount";
import {
  MyCatalogProductType,
  ProductStatusType,
  RequestMyCatalogFiltersType,
  RequestUploadsAutoFiltersType,
  RequestUploadsManualFiltersType,
  UploadAutoType,
  UploadManualType,
  UploadStatus
} from "app/types";

import css from "./style.module.css";

// components
import { Table, Tag, Tooltip } from "antd";
import Column from "antd/es/table/Column";
import ImageContainer from "../../../ui/ImageContainer/ImageContainer";
import TextWithTooltip from "../../../ui/TextWithTooltip/TextWithTooltip";

type UploadEntityType = UploadAutoType | UploadManualType;
type EntityType = MyCatalogProductType | UploadAutoType | UploadManualType;

const myCatalogStatuses: {[key: string]: string} = {
  published: "Размещен",
  moderation: "На модерации",
  rejected: "Отклонен",
  needs_update: "Отмена",
  canceled_by_client: "Требует обновления",
  error: "Ошибка",
  draft: "Черновик",
};

const uploadStatuses: {[key: string]: string} = {
  New: "Новый",
  Uploading: "Загрузка...",
  Paused: "Остановлено",
  Success: "Успешно",
  Error: "Ошибка"
};

// Типы колонок у таблицы
export type ProductColumnType =
  | "photo"
  | "name"
  | "category"
  | "bk_category"
  | "price"
  | "updated_at"
  | "download_source"
  | "status"
  | "actions"
  ;

export type CatalogColumnType =
  | "date_time"
  | "created_at"
  | "updated_at"
  | "periodicity"
  | "catalogue_type"
  | "status"
  | "file_type"
  | "file"
  | "actions"
  ;

export type TableColumnType = ProductColumnType | CatalogColumnType;

type FiltersType = RequestMyCatalogFiltersType | RequestUploadsAutoFiltersType | RequestUploadsManualFiltersType;

type RenderEntityType = EntityType & {
  key: string;
}

interface ICatalogTableProps {
  columns: TableColumnType[];
  list?: EntityType[];
  count?: number;
  fetchList?: (pagination, filters) => void;
  isFetching?: boolean;
  renderActions?: (entity: any, idx: number) => React.ReactNode;
  rowSelection?: TableRowSelection<any>;
  filters?: FiltersType;
  setPaginationInfo?: React.Dispatch<React.SetStateAction<TablePaginationConfig>>;
  setSorterInfo?: React.Dispatch<React.SetStateAction<SorterResult<any> | SorterResult<any>[]>>;
  emptyBlock?: React.ReactNode; // Пустой блок: isFetching + (list.length = 0) + (count = 0) + (isShowEmptyBlock = true)
  isShowEmptyBlock?: boolean; // Показывать пустой блок и тут можно свою проверку || true (см. выше)
  emptyExcludedFilters?: string[]; // Какие фильтры не учитывать в пустом блоке
  className?: string;
}

const CatalogTable: FC<ICatalogTableProps> = ({
  columns,
  list,
  count,
  fetchList,
  isFetching,
  rowSelection,
  renderActions,
  filters,
  setPaginationInfo,
  setSorterInfo,
  emptyBlock,
  isShowEmptyBlock = false,
  emptyExcludedFilters = [],
  className = "",
}) => {
  const navigate: NavigateFunction = useNavigate();

  const [showEmpty, setShowEmpty] = useState<boolean>(false);
  const [productsList, setProductsList] = useState<RenderEntityType[]>([]);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    total: count || 0,
    locale: {
      items_per_page: "/ на странице",
    },
  });
  const [sorter, setSorter] = useState<SorterResult<any> | SorterResult<any>[]>(null);

  const getRequestPagination = () => ({
    page: pagination.current,
    page_size: pagination.pageSize,
    ...(sorter ? { ordering: generateSortString(sorter) } : {}),
  });

  useEffect(() => {
    if (!isFetching && !!fetchList) {
      fetchList(getRequestPagination(), filters);
    }
  }, [filters, pagination.current, pagination.pageSize, generateSortString(sorter)]);

  useEffect(() => {
    setPagination((prevState) => ({ ...prevState, current: 1, total: count ?? 0 }));
  }, [count]);

  // Проверка на пустой блок только при смене isFetching
  // Другие решения могут вызывать лишний re-render
  useEffect(() => {
    !isFetching && setShowEmpty(
      !isFetching
      && !(list?.length > 0)
      && (count ?? 0) === 0
      && checkObjIsEmpty(filters, emptyExcludedFilters)
    );
  }, [isFetching]);

  useEffect(() => {
    setProductsList(
      list?.map((entity: EntityType, idx: number): RenderEntityType => ({
        key: idx.toString(),
        ...entity,
      })) || []
    );
  }, [list]);

  useEffect((): void => {
    if (setPaginationInfo && pagination) {
      setPaginationInfo(pagination);
    }
  }, [setPaginationInfo, pagination]);

  useEffect((): void => {
    if (sorter && !!setSorterInfo) {
      setSorterInfo(sorter);
    }
  }, [setSorterInfo, sorter]);

  const onPaginationChange = (
    pagination: TablePaginationConfig,
    _filters: any,
    sorter: SorterResult<any> | SorterResult<any>[]
  ): void => {
    setSorter(sorter);
    setPagination(pagination);
  };

  const getTagColor = (tag: ProductStatusType | UploadStatus): string => {
    switch (tag) {
      case "New":
        return "processing";
      case "published":
      case "Success":
        return "success";
      case "moderation":
      case "Uploading":
        return "warning";
      case "rejected":
      case "error":
      case "needs_update":
      case "Error":
        return "error";
      case "draft":
      case "Paused":
        return "default";
      default:
        return "default";
    }
  };

  const renderSearchNotFound = (): JSX.Element => <>Ничего не найдено</>;

  const renderColumnTitle = (title: string): JSX.Element => <span className="secondary-color">{title}</span>;

  const renderColumn = (column: TableColumnType): JSX.Element | null => {
    switch (column) {
      case "photo":
        return (
          <Column
            key="Photo"
            title={renderColumnTitle("Фото")}
            dataIndex="photo"
            width={65}
            fixed="left"
            className={css.cellPhoto}
            render={(image: string) => (
              <div className={css.photoWrapper}>
                <ImageContainer
                  src={image}
                  alt="Фото"
                  imageContainerClass={css.photo}
                  onClick={() => {}} // TODO: здесь будет навигация на форму редактирования продукта
                />
              </div>
            )}
          />
        );
      case "name":
        return (
          <Column
            key="Name"
            title={renderColumnTitle("Наименование товара")}
            dataIndex="name"
            width={200}
            sorter={{ multiple: 3 }}
            showSorterTooltip={false}
            sortDirections={["ascend", "descend"]}
            render={(text: string) => {
              return (
                <a
                  className="flex"
                  onClick={() => {}} // TODO: здесь будет навигация на форму редактирования продукта
                >
                  <TextWithTooltip text={text} />
                </a>
              );
            }}
          />
        );
      case "category":
        return (
          <Column
            key="Category"
            title={renderColumnTitle("Категория")}
            dataIndex="supplier_category"
            width={200}
            sorter={{ multiple: 3 }}
            showSorterTooltip={false}
            sortDirections={["ascend", "descend"]}
            render={(value: string) => <TextWithTooltip text={value} />}
          />
        );
      case "bk_category":
        return (
          <Column
            key="Bk_category"
            title={renderColumnTitle("Категория БК")}
            dataIndex="category_bk"
            width={200}
            sorter={{ multiple: 3 }}
            showSorterTooltip={false}
            sortDirections={["ascend", "descend"]}
            render={(value: string) => value ? <TextWithTooltip text={value} /> : <span>-</span>}
          />
        );
      case "price":
        return (
          <Column
            key="Price"
            title={renderColumnTitle("Цена")}
            dataIndex="price"
            width={200}
            sorter={{ multiple: 3 }}
            showSorterTooltip={false}
            sortDirections={["ascend", "descend"]}
            render={(value: number, entity: MyCatalogProductType) =>
              <TextWithTooltip text={`${value} ${entity?.currency}`} />
            }
          />
        );
      case "created_at":
        return (
          <Column
            key="created_at"
            title={renderColumnTitle("Дата создания")}
            dataIndex="created_at"
            sorter={{ multiple: 3 }}
            showSorterTooltip={false}
            className="select-none"
            width={150}
            sortDirections={["ascend", "descend"]}
            render={(value: string) => {
              return (
                <>{dayjs(value)?.format("DD.MM.YYYY HH:mm")}</>
              );
            }}
          />
        );
      case "updated_at":
        return (
          <Column
            key="Date changes"
            title={renderColumnTitle("Последнее изменение")}
            dataIndex="updated_at"
            width={150}
            sorter={{ multiple: 3 }}
            sortDirections={["ascend", "descend"]}
            showSorterTooltip={false}
            render={(value: string) => <>{dayjs(value).format("DD.MM.YYYY HH:mm")}</>}
          />
        );
      case "download_source":
        return (
          <Column
            key="Download_source"
            title={renderColumnTitle("Источник загрузки")}
            dataIndex="source"
            width={150}
            sorter={{ multiple: 3 }}
            sortDirections={["ascend", "descend"]}
            showSorterTooltip={false}
            render={(value: string) => {
              const source: string = value === "manual" ? "Ручной" : value?.toUpperCase();

              return <Tag color="default">{source}</Tag>;
            }}
          />
        );
      case "status":
        return (
          <Column
            key="Status"
            title={renderColumnTitle("Статус")}
            dataIndex="status"
            width={150}
            sorter={{ multiple: 3 }}
            showSorterTooltip={false}
            sortDirections={["ascend", "descend"]}
            render={(value: ProductStatusType, entity: EntityType) => {
              return (
                <Tooltip title={(entity as UploadEntityType)?.error ?? null}>
                  <Tag
                    color={getTagColor(value)}
                    className="w-fit cursor-default"
                  >
                    {myCatalogStatuses[value] || uploadStatuses[value]}
                  </Tag>
                </Tooltip>
              );
            }}
          />
        );
      case "date_time":
        return (
          <Column
            key="Date_time"
            title={renderColumnTitle("Дата и время")}
            dataIndex="created_at"
            width={150}
            sorter={{ multiple: 3 }}
            showSorterTooltip={false}
            sortDirections={["ascend", "descend"]}
            render={(value: string) => {
              return (
                <>{dayjs(value).format("DD.MM.YYYY HH:mm")}</>
              );
            }}
          />
        );
      case "catalogue_type":
        return (
          <Column
            key="Catalogue_type"
            title={renderColumnTitle("Где загружено")}
            dataIndex="catalogue_type"
            width={250}
            render={(value, _entity) => value}
          />
        );
      case "periodicity":
        return (
          <Column
            key="Periodicity"
            dataIndex="update_period"
            width={100}
            title={renderColumnTitle("Регулярность обновления")}
            sorter={{ multiple: 3 }}
            showSorterTooltip={false}
            sortDirections={["ascend", "descend"]}
            render={(value: number) => (
              <div>{value} {calcDeclensionByCount(value, ["час", "часа", "часов"])}</div>
            )}
          />
        );
      case "file_type":
        return (
          <Column
            key="File_type"
            title={renderColumnTitle("Тип файла")}
            dataIndex="file_type"
            width={100}
            sorter={{ multiple: 3 }}
            showSorterTooltip={false}
            sortDirections={["ascend", "descend"]}
            render={(_value, _entity) => {
              // TODO: добавить реальные данные
              return (
                <Tag color="default">YML</Tag>
              );
            }}
          />
        );
      case "file":
        return (
          <Column
            key="File"
            title={renderColumnTitle("Файл")}
            dataIndex="file"
            width={300}
            render={(_value, entity: UploadManualType | UploadAutoType) => {
              const file = {
                link: (entity as UploadManualType)?.file
                  ? (entity as UploadManualType)?.file?.url
                  : (entity as UploadAutoType)?.link,
                fileName: (entity as UploadManualType)?.file
                  ? (entity as UploadManualType)?.file?.file_name
                  : (entity as UploadAutoType)?.link
              };

              return (
                <a
                  href={file.link}
                  target="_blank"
                  rel="noreferrer"
                  className="blue-color"
                >
                  {file.fileName}
                </a>
              );
            }}
          />
        );
      case "actions":
        return (
          <Column
            key="Action"
            title=""
            dataIndex="action"
            width={100}
            fixed="right"
            render={(
              _value: string,
              entity: UploadEntityType,
              index: number
            ) => renderActions((entity as UploadAutoType), index)}
          />
        );

      default:
        return null;
    }
  };

  return !!emptyBlock && isShowEmptyBlock && showEmpty ? (
    emptyBlock
  ) : (
    <div className={css.tableWrapper}>
      <Table
        loading={isFetching}
        dataSource={productsList}
        pagination={pagination}
        onChange={onPaginationChange}
        rowSelection={columns && (rowSelection || null)}
        className={`${css.table} ${className}`}
        locale={{ emptyText: renderSearchNotFound() }}
        scroll={{ y: "55vh" }}
      >
        {columns && columns.map(renderColumn)}
      </Table>
    </div>
  );
};

export default CatalogTable;
