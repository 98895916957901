import React, { JSX, FC, ReactNode, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MenuInfo } from "rc-menu/lib/interface";
import { Layout, Menu } from "antd";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { ReactComponent as MainIcon } from "../../assets/icons/main-icon.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit-icon.svg";
import { ReactComponent as FileIcon } from "../../assets/icons/file-icon.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/user-icon.svg";
import { ReactComponent as InterfaceIcon } from "../../assets/icons/interface-icon.svg";
import { ReactComponent as ReportsIcon } from "../../assets/icons/reports-icon.svg";
import { ReactComponent as WorkflowIcon } from "../../assets/icons/workflow-icon.svg";
import { ReactComponent as BriefcaseIcon } from "../../assets/icons/briefcase-icon.svg";

import css from "./style.module.css";

interface MenuItemProps {
  key: string;
  icon: ReactNode;
  label: string | ReactNode;
  url: string;
  isShow: boolean;
  onClick?: () => void;
}

interface ISidebar {
  isVendor: boolean;
}

const Sidebar: FC<ISidebar> = ({isVendor}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [current, setCurrent] = useState<string>("");

  useEffect(() => {
    const currentMenu: string = MAIN_MENU_ITEMS
      .find((item) => item.url === location.pathname)?.key
      || "";

    setCurrent(currentMenu);
  }, [location]);

  const handleSelectItem = (e: MenuInfo): void => {
    setCurrent(e.key);
  };

  const renderMenuItem: FC<MenuItemProps> = ({
    key,
    icon,
    label,
    url,
    onClick
  }): JSX.Element => (
    <Menu.Item
      key={key}
      icon={icon}
      className={`${css.menu_item} ${key === current ? css.selected : ""}`}
      onClick={() => {
        onClick && onClick();
        navigate(url);
      }}
    >
      {label}
    </Menu.Item >
  );

  const MAIN_MENU_ITEMS: MenuItemProps[] = [
    {
      key: "route-main",
      icon: <MainIcon className={css.menu_icon} />,
      label: "Главная",
      url: isVendor ? "/platform/home" : "/",
      isShow: true,
    },
    {
      key: "route-trades",
      icon: <EditIcon className={css.menu_icon} />,
      label: "Запросы",
      url: "/trades",
      isShow: !isVendor,
    },
    {
      key: "route-catalog",
      icon: <FileIcon className={css.menu_icon} />,
      label: "Каталог",
      url: "/new/platform/catalog",
      isShow: true,
    },
    {
      key: "route-suppliers",
      icon: <UserIcon className={css.menu_icon} />,
      label: "Поставщики",
      url: "/new/platform/suppliers",
      isShow: true,
    },
    {
      key: "route-orders",
      icon: <InterfaceIcon className={css.menu_icon} />,
      label: "Заказы",
      url: "/orders",
      isShow: true,
    },
    {
      key: "route-import",
      icon: <FileIcon className={css.menu_icon} />,
      label: "Мой каталог товаров",
      url: "/import",
      isShow: true,
    },
    {
      key: "route-reports",
      icon: <ReportsIcon className={css.menu_icon} />,
      label: "Отчеты",
      url: "/reports",
      isShow: true,
    },
    {
      key: "route-workflow",
      icon: <WorkflowIcon className={css.menu_icon} />,
      label: "Документооборот",
      url: "/workflow",
      isShow: !isVendor,
    },
    {
      key: "route-smart-business",
      icon: <BriefcaseIcon className={css.menu_icon} />,
      label: "Умный бизнес",
      url: "/new/platform/smart-business",
      isShow: !isVendor,
    },
  ];

  const filteredMenuItems: MenuItemProps[] = MAIN_MENU_ITEMS.filter(({isShow}) => isShow);

  return (
    <Layout.Sider
      theme="light"
      className={css.left_sidebar}
      width="15rem"
      style={{ position: "sticky" }}
    >
      <div>
        <Link to="/">
          <Logo className={css.sidebar_logo} />
        </Link>
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={[current]}
          style={{ borderInlineEnd: "none" }}
          className={css.sidebar_menu}
          selectedKeys={[current]}
          onClick={handleSelectItem}
        >
          {filteredMenuItems.map(renderMenuItem)}
        </Menu>
      </div>
    </Layout.Sider>
  );
};

export default Sidebar;
