import { SorterResult } from "antd/es/table/interface";

export const generateSortString = (sorter: SorterResult<any> | SorterResult<any>[]): string => {
  if (!sorter) {
    return "";
  }

  if (Array.isArray(sorter)) {
    return sorter.map((item) => generateSortString(item)).join(",");
  } else if (sorter?.field && sorter?.order) {
    const sortOrder: string = sorter.order === "descend" ? "-" : "";

    return `${sortOrder}${sorter.field}`;
  }
  return null;
};

export default generateSortString;
