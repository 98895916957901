export const calcDeclensionByCount = (count: number, words: string[]): string => {
  const a: number = Math.abs(count) % 100;
  const b: number = a % 10;

  if (a > 10 && a < 20) { return words[2]; }
  if (b > 1 && b < 5) { return words[1]; }
  if (b === 1) { return words[0]; }

  return words[2];
};
