import * as process from "process";
import axios from "../utils/axios";
import { RequestMyCatalogFiltersType, RequestPaginationType } from "app/types";

const API_BASE: string | undefined = process.env.REACT_APP_API_BASE_URL?.trimEnd();

export const getMyCatalog = async (params: RequestPaginationType | RequestMyCatalogFiltersType): Promise<any> => {
  return axios.GET(API_BASE, `/api/my-catalog?${getSearchParams(params).toString()}`);
};

export const getPriceTypeList = async (): Promise<any> => {
  return axios.GET(API_BASE, "/api/price-type-list");
};

export const getCategories = async (): Promise<any> => {
  return axios.GET(API_BASE, `/api/categories`);
};

// Массовое или единичное удаление товаров из Моего каталога
export const deleteFromMyCatalog = async (ids: string[]): Promise<any> => {
  return axios.DELETE(API_BASE, "/api/my-catalog", {
    ids
  });
};
