import React from "react";
import ReactDOM from "react-dom/client";

import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "./redux/store/store";
import { setupResponseInterceptor } from "./utils/axios";

import "./index.css";
import App from "./App";
import { ConfigProvider } from "antd";
import { ThemeConfig } from "antd/es/config-provider/context";
import locale from "antd/locale/ru_RU";
import { customTheme } from "./constants/customTheme";

setupResponseInterceptor(store);

const root: ReactDOM.Root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ConfigProvider theme={customTheme as ThemeConfig} locale={locale}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </ConfigProvider>
  </React.StrictMode>
);
