import React, { JSX, FC } from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import css from "./style.module.css";

interface IGlobalSearchProps {

}

const GlobalSearch: FC<IGlobalSearchProps> = (): JSX.Element => {
  return (
    <div>
      <Input
        className={css.search}
        prefix={<SearchOutlined />}
        placeholder="Поиск по товарам и поставщикам"
      />
    </div>
  );
};

export default GlobalSearch;
